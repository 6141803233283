

























































import { mapGetters } from "vuex";
const sound = require("../audio/audio.mp3");
const click = require("@/audio/click.mp3");
import jwt_decode from "jwt-decode";
   import Vue from 'vue';
  import SimpleVueValidation from 'simple-vue-validator';
  const Validator = SimpleVueValidation.Validator;

  Vue.use(SimpleVueValidation);
export default {
  name: "SignIn",
  computed: {
    ...mapGetters("accounts", ["getChainData", "isUserConnected"]),
  },
   created: function() {
let component = this;
if(this.$route.params.ref){
   this.referrer= this.$route.params.ref
}
if(/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(component.$route.params.id)==true){
    this.tokenValid=true
this.token=this.$route.params.id.toString();
 let decoded = jwt_decode<any>(component.$route.params.id.toString());     
 
 if (decoded.nick==''||decoded.nick==undefined){
component.register=true;

if(decoded.discordName){
    component.discordUsername=decoded.discordName
}
 }else{
     component.register=false;
   component.$store.dispatch("accounts/connectDiscord",{token:component.$route.params.id});

	  	         setTimeout( () => component.$router.push({ name: 'Home'}), 3000);
 }
}else{
this.tokenValid=false
}

},
    validators: {
      nick: function(value) {
        return Validator.value(value).required().regex(/^[A-Za-z0-9_]{3,20}$/, 'Alphanumeric (3-20 letters). Underscore is optional');
      },
      referrer: function(value) {
         return Validator.value(value).regex(/^[A-Za-z0-9_]{3,20}$/, 'Alphanumeric (3-20 letters). Underscore is optional');
      },
    },
  methods:{
       registerAccount: function() {
          let component = this;
        this.$validate()
          .then(function(success) {
            if (success) {
           
   var data = { jwt: component.token,
                nick: component.nick,
                referrer: component.referrer     };
fetch('https://api2.supa.foundation/signup', {
    
       method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
 
})
.then(response => response.json()) 
.then(json => {
   if (json.error==true || json.error>0){
       console.log(json)
    component.$vs.notification({
           color: 'danger',
            position:'top-center',
            title: 'Registration Error!',
            text: json.response
          })
  }else{

component.$store.dispatch("accounts/connectDiscord",{token:json.token});
component.$router.push({ name: 'Home' }); 
  }})




            }
          });
      },
      reset: function() {
        this.nick = '';
        this.referrer = '';
       
        this.validation.reset();
      },
    openTwitterLogin: function(){
let component = this;
	  	   component.$store.dispatch("accounts/connectDiscord",{token:component.$route.params.id});
component.$router.push({ name: 'Home' });    },
   
playmusic(){
this.playMusic=true;
this.audio.loop=true;
this.audio.play();

},
pausemusic(){
this.playMusic=false;
this.audio.pause();

},
 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
  
   data() {
    return {
        audioclick:new Audio(click),
      playMusic:false,
      audio:new Audio(sound),
         register:true,
tokenValid:false,
referrer:'',
discordUsername:null,
token:'',
nick:'',

    };
  },
};
