





































































































import { mapGetters, mapActions } from "vuex";
const click = require("@/audio/click.mp3");

import { ethers } from "ethers";
import SUPACELLPODEPIC from "@/contracts/SUPACELLPODEPIC.json";
const success = require("@/audio/success.mp3");
import addresses from "@/contracts/addresses.json";
export default {
  name: "SUPACELLPODEPIC",
  computed: {
            ...mapGetters("controls", ["isPlaying"]),

  ...mapGetters("accounts", ["getChainData", "isUserConnected",  "getWeb3Provider","isInitialized",
      "isUserConnected",
      "getChainId","getAccountDetails","getActiveAccount"
])  },
   async created() {
            let component=this

        this.organismId= this.$route.params.id
 
     this.$store.dispatch("accounts/getIsLoggedIn");
       await this.connect();

    if (!this.getWeb3Provider) {
      //document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();

      const SUPACELLPODEPICAddress = addresses.SUPACELLPODEPIC[parseInt(this.getChainId)];

       this.SUPACELLPODEPICContract=new ethers.Contract(SUPACELLPODEPICAddress, SUPACELLPODEPIC.abi, signer);


    try {
         
        
               let jsonLocation=await  this.SUPACELLPODEPICContract.tokenURI(this.organismId);
            fetch(jsonLocation)
            .then(response => response.json())
    .then(async data => {
      this.NFTdata=data;


    })
           

          
                                
            this.color=await  this.SUPACELLPODEPICContract.podType( this.organismId);
            





      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }
  

let component=this
  
    
    
 



   

    
    }
  },
  methods:{
  
      goBack() {
     this.$router.push('/supacellpods') 
    },
            ...mapActions("accounts", ["connect"]),
viewOrganisms(id){
  this.$router.push('/supaorganism/'+id)
},

playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
 playSUPASound(){
        let audio = this.$refs.audio;
          audio.play();
        
    },
 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
   data() {
    return {
        audiosuccess:new Audio(success),

        audioclick:new Audio(click),
      playMusic:false,
      SUPAOrganismCount:0,
      SUPAVirusCount:0,
      SUPAOrganismList:[],
      isActive:false,
      NFTdata:null,
      organismIdentified:true,
      waiting:false,
      isOwner:false,
      mutationsIdentified:false,
      SUPASound:null,
      lastFarmed:1,
      color:0

    };
  },
};
