


































import { mapGetters } from "vuex";
const sound = require("../audio/audio.mp3");
const click = require("@/audio/click.mp3");




export default {
  name: "Refer",
  computed: {
    ...mapGetters("accounts", ["getChainData", "isUserConnected","isUserLoggedIn"]),
  },
   created: function() {
let component = this;
if(this.$route.params.ref){
   this.referrer= this.$route.params.ref
}


},

  methods:{
     openDiscordLogin: function(){
                        if(this.referrer!=''){
                          console.log(this.referrer)
                        window.location.href = 'https://api2.supa.foundation/auth/discord?state='+this.referrer;

                        }else{
                          window.location.href = 'https://api2.supa.foundation/auth/discord'
                        }

    },
 
   
playmusic(){
this.playMusic=true;
this.audio.loop=true;
this.audio.play();

},
pausemusic(){
this.playMusic=false;
this.audio.pause();

},
 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
  
   data() {
    return {
        audioclick:new Audio(click),
      playMusic:false,
      audio:new Audio(sound),
         register:true,
tokenValid:false,
referrer:'',
discordUsername:null,
token:'',
nick:'',

    };
  },
};
