

import Web3Modal from "web3modal";
import BurnerConnectProvider from "@burner-wallet/burner-connect-provider";
import { ethers } from "ethers";
import addresses from "@/contracts/addresses.json";
import chains from "@/contracts/chains.json";
import jwt_decode from "jwt-decode";
import SUPAFoundation from "@/contracts/SUPAFoundation.json";
// import WalletConnectProvider from '@walletconnect/web3-provider'
// export const defaultProvider = new ethers.providers.InfuraProvider(null, process.env.VUE_APP_INFURA_ID);

const providerOptions = {
  /*
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.VUE_APP_INFURA_ID,
      rpc: {
        56: 'https://bsc-dataseed.binance.org/',
      },
    }
  },
  */
  burnerconnect: {
    package: BurnerConnectProvider,
    options: {
      defaultNetwork: "100",
    },
  },
};

export const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions,
});

export const ethersProvider = null;
export const web3Provider = null;

// initial state
const state = () => ({
  discordRefreshedTimes:0,
  isInitialized: false,
  isConnected: false,
  activeAccount: null,
  activeBalance: 0,
  chainId: null,
  chainData: null,
  chainSupported: true,
  error: null,
  web3Provider: null,
  isLoggedIn: false,
  userToken: null,
  discordUserImg:null,
  discordUsername:null,
  SUPAUsername:null,
  discordRole:0,
  accountDetails: null,
  groupDetails: null,
  supaBalance:0,
  discordUserID:null,
});
//"isUserLoggedIn","getUserToken"
// getters
const getters = {
  isInitialized(state) {
    return state.isInitialized;
  },
  isUserConnected(state) {
    return state.isConnected;
  },
  getActiveAccount(state) {
    return state.activeAccount;
  },
  getActiveBalanceWei(state) {
    return state.activeBalance;
  },
  getSUPABalanceWei(state) {
    return state.supaBalance;
  },
  getSUPABalanceEth(state) {
    return ethers.utils.formatEther(state.supaBalance);

  },
  getActiveBalanceEth(state) {
    return ethers.utils.formatEther(state.activeBalance);
  },
  getChainId(state) {
    return state.chainId;
  },
  getChainData(state) {
    return state.chainData;
  },
  getChainSupported(state) {
    return state.chainSupported;
  },
  getWeb3Provider(state) {
    return state.web3Provider;
  },
  isUserLoggedIn(state) {
  
    return state.isLoggedIn;
  },
  getUserToken(state){
    return state.userToken;
  },
   getDiscordUsername(state){
    return state.discordUsername;
  },
   getDiscordUserImg(state){
    return state.discordUserImg;
  },
  getSUPAUsername(state){
    return state.SUPAUsername;
  },
  getDiscordRole(state){
    return state.discordRole;
  },
  getDiscordID(state){
    return state.discordUserID;
  },
  getAccountDetails(state) {
    return state.accountDetails;
  },
  getGroupDetails(state) {
    return state.groupDetails;
  },
 
};

// actions
const actions = {
  async connect({ commit, dispatch, getters }) {
    const web3Provider = await web3Modal.connect();
   
    web3Provider.on("chainChanged", (chainId) => {
      commit("setChainData", chainId);
      actions.fetchActiveBalance({ commit, getters });
      window.location.reload();
    });

    web3Provider.on("accountsChanged", async (accounts) => {
      
      if (accounts.length === 0) {
        dispatch("disconnect");
      } else {
        const signer = ethersProvider.getSigner(accounts[0]);
        const network = await ethersProvider.getNetwork();
        commit("setActiveAccount", await signer.getAddress());
        commit("setChainData", network.chainId);
        commit("connected", true);
        actions.fetchActiveBalance({ commit, getters });
      }
    });

    web3Provider.on("connect", async (/*connectInfo*/) =>  {
      // TODO
     
      // get the contract instance
   
    });

    web3Provider.on("disconnect", async (error) => {
      console.error(error);
      dispatch("disconnect");
    });

    const ethersProvider = new ethers.providers.Web3Provider(web3Provider);
    const signer = ethersProvider.getSigner();
    const network = await ethersProvider.getNetwork();
    const address = await signer.getAddress();

    commit("setWeb3Provider", web3Provider);
    commit("setActiveAccount", address);
    commit("setChainData", network.chainId);
    commit("setIsConnected", true);
    commit("setIsInitialized", true);
    actions.fetchActiveBalance({ commit, getters });
  },

  disconnect({ commit }) {
    web3Modal.clearCachedProvider();
    // localStorage.removeItem("walletconnect");
    commit("setWeb3Provider", null);
    commit("setActiveAccount", "");
    commit("setChainData", null);
    commit("setIsConnected", false);
    commit("setActiveBalance", 0);
    commit("setError", "");

    window.location.href = "../";
  },

  init({ commit, dispatch }) {
    if (web3Modal.cachedProvider) {
      dispatch("connect");
    } else {
      commit("setIsInitialized", true);
    }
  },

  async fetchActiveBalance({ commit, getters }) {
    const ethersProvider = new ethers.providers.Web3Provider(
      getters.getWeb3Provider
    );
    const balance = await ethersProvider.getBalance(getters.getActiveAccount);
    commit("setActiveBalance", balance);
    
    const network = await ethersProvider.getNetwork();

    if(network.chainId==250 || network.chainId==4002){
      let signer = ethersProvider.getSigner();
      const address = await signer.getAddress();

      const contractAddress = addresses.SUPAFoundation[network.chainId];

      this.contract = new ethers.Contract(contractAddress, SUPAFoundation.abi, signer);

      try {
        const SUPABalance = await this.contract.balanceOf(address);
        
        commit('setSUPABalance',SUPABalance)
      } catch (err) {
        console.log(err);
      }


    }





  },
  async connectDiscord({ commit }, params) {
    if(/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(params.token)==true){
      var decoded = jwt_decode<any>(params.token.toString());
      const timestamp = Math.round(Date.now() / 1000); 
      if (decoded.exp>timestamp){

          commit("setIsLoggedIn", true);
 //console.log( jwtToken.token)
 commit("setUserToken",params.token)
      }
    }
  
   

},
async disconnectDiscord({ commit }) {
 commit("deleteUserToken");
 commit("setIsLoggedIn", false);
},


async getIsLoggedIn({ commit }) {
  if(localStorage.getItem('jwtToken') !== null){
    var token=localStorage.getItem('jwtToken')!.toString();
    var decoded = jwt_decode<any>(token);
    const timestamp = Math.round(Date.now() / 1000); 
    if (decoded.exp>timestamp){

      
        let formData = new FormData();
        formData.append('token', token);
         fetch('https://api.supa.foundation/refreshDiscordUser', {
     
      method: "POST",
   body: formData,
  
 })
 .then(response => response.json()) 
 .then(json => {
   
    if (json.error==true){


      commit("setIsLoggedIn", false);
      commit("deleteUserToken");
     
   }else{
     if(state().discordRefreshedTimes<2){
    var data = { jwt: token  };
fetch('https://api2.supa.foundation/refreshDiscordRole', {

method: "POST",
body: JSON.stringify(data),
headers: {
'Content-Type': 'application/json'
},

})
.then(response => response.json()) 
.then(json2 => {
if (json2.error>0){
console.log(json2)
commit("setIsLoggedIn", false);
commit("deleteUserToken");
}else{
json.userinfo[0].hasTitan=json2.userInfo.hasTitan
json.userinfo[0].hasIDO=json2.userInfo.hasIDO
json.userinfo[0].hasGenesis=json2.userInfo.hasGenesis
commit("setIsLoggedIn", true);

 commit("saveAccountDetails",json.userinfo[0])
 commit("saveGroupInfo",json.group)
 //console.log( jwtToken.token)
 commit("setDiscordRefreshedTimes")
 commit("setUserToken",localStorage.getItem('jwtToken'))

}})
   } else{
    commit("setIsLoggedIn", true);

    commit("saveAccountDetails",json.userinfo[0])
    commit("saveGroupInfo",json.group)
    //console.log( jwtToken.token)
    commit("setUserToken",localStorage.getItem('jwtToken'))
   }

   }
  
  
  })
     

     
    }else{

      commit("setIsLoggedIn", false);
      commit("deleteUserToken");
    }

  }
  
   },






};

// mutations
const mutations = {
 setSUPABalance(state,value){
state.supaBalance=value;
 },
  setUserToken(state,token){

    state.userToken=token.toString();
    localStorage.setItem('jwtToken',token.toString());
      var decoded = jwt_decode<any>(token.toString());
 // console.log(token.toString())
     state.discordUserImg=decoded.img;
     state.discordUsername=decoded.discordName;
     state.discordUserID=decoded._id;
     state.SUPAUsername=decoded.nick;
     state.discordRole=decoded.discordRole;
    },
  setDiscordRefreshedTimes(){
    state().discordRefreshedTimes++;
  },
  saveAccountDetails(state, accountInfo) {
    state.accountDetails = accountInfo;

    localStorage.setItem('accountInfo',JSON.stringify(accountInfo))
  
  },
  saveGroupInfo(state, groupInfo) {
    state.groupDetails = groupInfo;
    localStorage.setItem('groupInfo',JSON.stringify(groupInfo))

  },
  setWeb3Provider: function (state, value) {
    state.web3Provider = value;
  },
  setIsInitialized: function (state, value) {
    state.isInitialized = value;
  },
  setIsConnected: function (state, value) {
    state.isConnected = value;
  },
  setError: function (state, value) {
    state.error = value;
  },
  setActiveAccount: function (state, value) {
    state.activeAccount = value;
  },
  setActiveBalance: function (state, value) {
    state.activeBalance = value;
  },
  setChainData: function (state, value) {
    const production = process.env.NODE_ENV === "production";
    const testNetworkIds = [1337, 31337];
    const id = parseInt(value);

    const defaultData = {
      name: id === 1337 ? "Localhost" : "Unknown",
      chainId: id,
      networkId: id,
    };

    let chainData = defaultData;
    state.chainId = id;

    if (value !== null) {
      let chainSupported = addresses.Greeter[value] !== undefined;
      if (production) {
        if (testNetworkIds.includes(state.chainId)) {
          chainSupported = false;
        }
      }
      state.chainSupported = chainSupported;
      chainData = chains.filter((c) => c.chainId === value)[0];
    }
    state.chainData = chainData || defaultData;
  },
  deleteUserToken(state) {
    localStorage.removeItem('jwtToken');
    state.discordUserImg=null;
    state.discordUserID=null;
    state.discordUsername=null;
    state.SUPAUsername=null;
    state.discordRole=0;
   },


    setIsLoggedIn(state, isLoggedIn) {
      

      state.isLoggedIn = isLoggedIn;
      localStorage.setItem('isLoggedIn', isLoggedIn);
  
    },
   
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
