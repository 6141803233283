








































































import { mapGetters, mapActions } from "vuex";
const click = require("@/audio/click.mp3");
import { ethers } from "ethers";
import SUPAFoundation from "@/contracts/SUPAFoundation.json";
import SUPACell from "@/contracts/SUPACell.json";
import SUPAVirus from "@/contracts/SUPAVirus.json";
import SUPAOrganism from "@/contracts/SUPAOrganism.json";

import addresses from "@/contracts/addresses.json";
export default {
  name: "SUPAOrganisms",
  computed: {
            ...mapGetters("controls", ["isPlaying"]),

  ...mapGetters("accounts", ["getChainData", "isUserConnected",  "getWeb3Provider","isInitialized",
      "isUserConnected",
      "getChainId","getAccountDetails","getActiveAccount"
])  },
   async created() {
            let component=this

        this.organismId= this.$route.params.id
    fetch('https://supafoundation.b-cdn.net/SUPAOrganismjson/'+this.organismId+'.json', { 
            method: 'GET'
          })
          .then(function(response) { return response.json(); })
          .then(function(json) {
          
          component.NFTdata=json
           
          });
     this.$store.dispatch("accounts/getIsLoggedIn");
       await this.connect();

    if (!this.getWeb3Provider) {
      //document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();

  const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];

       this.supaOrganismContract=new ethers.Contract(supaOrganismContractAddress, SUPAOrganism.abi, signer);

  const supaVirusContractAddress = addresses.SUPAVirus[parseInt(this.getChainId)];

       this.supaVirusContract=new ethers.Contract(supaVirusContractAddress, SUPAVirus.abi, signer);

  const supaCellContractAddress = addresses.SUPACell[parseInt(this.getChainId)];

       this.supaCellContract=new ethers.Contract(supaCellContractAddress, SUPACell.abi, signer);
let component=this
  this.supaCellContract.on("mintSuccessful", async (tokenId) => {
    if(await this.supaCellContract.ownerOf(tokenId)==address){

     this.$router.push('/supacell/'+tokenId)

    }
    
    
      });
  this.supaVirusContract.on("mintSuccessful", async (tokenId) => {
    if(await this.supaCellContract.ownerOf(tokenId)==address){

     this.$router.push('/virus/'+tokenId)

    }
    
    
      });



    try {
         if(await this.supaOrganismContract.ownerOf(this.organismId)==address){
        
         this.organismIdentified= await this.supaOrganismContract.seenNoncesTokenId(this.organismId);
         }


      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }

    
    }
  },
  methods:{
      goBack() {
     this.$router.push('/supaorganisms')
    },
            ...mapActions("accounts", ["connect"]),
viewOrganisms(id){
  this.$router.push('/supaorganism/'+id)
},
async identifyOrganism(){
               this.waiting = true;

         let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
  const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];
       this.supaOrganismContract=new ethers.Contract(supaOrganismContractAddress, SUPAOrganism.abi, signer);
       try {
       let transaction=  await this.supaOrganismContract.identifyOrganism(this.organismId);
                await transaction.wait().then(async (receipt) => {
               this.$toasted.global.app_success({
               message: "Congrats! You have successfully identified the Organism",
               });
               this.waiting = false;
                
               })
           }
           catch(err){
             this.$toasted.global.app_error();
            this.waiting = false;
            console.log(err);
           }


    

},
playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
   data() {
    return {
        audioclick:new Audio(click),
      playMusic:false,
      SUPAOrganismCount:0,
      SUPACellCount:0,
      SUPAVirusCount:0,
      SUPAOrganismList:[],
      isActive:false,
      NFTdata:null,
      organismIdentified:true,
      waiting:false

    };
  },
};
