


























































































const success = require("@/audio/success.mp3");

import { mapGetters, mapActions } from "vuex";
import { ethers } from "ethers";
import SUPAFoundation from "@/contracts/SUPAFoundation.json";
import SUPACell from "@/contracts/SUPACell.json";
import SUPAVirus from "@/contracts/SUPAVirus.json";
import SUPAOrganism from "@/contracts/SUPAOrganism.json";
import MutationPointsFarming from "@/contracts/MutationPointsFarming.json";
const click = require("@/audio/click.mp3");

import addresses from "@/contracts/addresses.json";
export default {
  name: "MutationPoints",
  computed: {
            ...mapGetters("controls", ["isPlaying"]),

  ...mapGetters("accounts", ["getChainData", "isUserConnected",  "getWeb3Provider","isInitialized",
      "isUserConnected",
      "getChainId","getAccountDetails","getActiveAccount"
])  },
   async created() {
     this.$store.dispatch("accounts/getIsLoggedIn");
       await this.connect();

    if (!this.getWeb3Provider) {
      //document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();

  const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];

       this.supaOrganismContract=new ethers.Contract(supaOrganismContractAddress, SUPAOrganism.abi, signer);

  const supaVirusContractAddress = addresses.SUPAVirus[parseInt(this.getChainId)];

       this.supaVirusContract=new ethers.Contract(supaVirusContractAddress, SUPAVirus.abi, signer);

  const supaCellContractAddress = addresses.SUPACell[parseInt(this.getChainId)];

       this.supaCellContract=new ethers.Contract(supaCellContractAddress, SUPACell.abi, signer);
           const mutationPointsFarmingContractAddress = addresses.MutationPointsFarming[parseInt(this.getChainId)];

       this.mutationPointsFarmingContract=new ethers.Contract(mutationPointsFarmingContractAddress , MutationPointsFarming.abi, signer);
             this.oldmutationPointsFarmingContract=new ethers.Contract("0xa61a681E3434430cB0b476B7D7645FbF3fe9FB81" , MutationPointsFarming.abi, signer);

       
    try {
         this.playerMutationPoints= await this.mutationPointsFarmingContract.playerMutationPoints(address)
         this.SUPAOrganismCount= await this.supaOrganismContract.balanceOf(address);
         this.SUPACellCount= await this.supaCellContract.balanceOf(address);
         this.playerOldMutationPoints= await this.oldmutationPointsFarmingContract.playerMutationPoints(address);
         if(await  this.mutationPointsFarmingContract.importedMP(address)==false&&(this.playerOldMutationPoints[0]>0 || this.playerOldMutationPoints[1]>0 || this.playerOldMutationPoints[2]>0|| this.playerOldMutationPoints[3]>0|| this.playerOldMutationPoints[4]>0|| this.playerOldMutationPoints[5]>0|| this.playerOldMutationPoints[6]>0)){
            this.showClaimOldMP=true;
         }
         this.SUPAVirusCount= await this.supaVirusContract.balanceOf(address);


      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }

    
    }
  },
  methods:{
            ...mapActions("accounts", ["connect"]),
viewOrganisms(){
  this.$router.push('/supaorganisms')
},
viewSUPACells(){
  this.$router.push('/supacells')
},
viewViruses(){
  this.$router.push('/viruses')
},
viewMutationPoints(){
  this.$router.push('/mutationpoints')
},
playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
async getOldMP(){
  this.waiting=true;
 let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();
   const mutationPointsFarmingContractAddress = addresses.MutationPointsFarming[parseInt(this.getChainId)];
       this.mutationPointsFarmingContract=new ethers.Contract(mutationPointsFarmingContractAddress , MutationPointsFarming.abi, signer);
          try {
       let transaction=  await this.mutationPointsFarmingContract.importOldPoints();
                await transaction.wait().then(async (receipt) => {
               this.$toasted.global.app_success({
               message: "Congrats! Have imported your Mutation Points Balance",
               });
               this.waiting = false;
                this.audiosuccess.play();
            this.playerMutationPoints= await this.mutationPointsFarmingContract.playerMutationPoints(address)

               })
           }
           catch(err){
             this.$toasted.global.app_error();
            this.waiting = false;
            console.log(err);
           }
},
  goBack() {
     this.$router.push('/main') 
    },

 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
   data() {
    return {
              audiosuccess:new Audio(success),

      playMusic:false,
 showClaimOldMP:false,
      SUPAOrganismCount:0,
      SUPACellCount:0,
      SUPAVirusCount:0,
       playerMutationPoints:[0,0,0,0,0,0,0]  ,
        oldPlayerMutationPoints:[0,0,0,0,0,0,0],
               audioclick:new Audio(click),


    };
  },
};
