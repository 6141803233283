import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import SignIn from "../views/SignIn.vue";
import Mint from "../views/Mint.vue";
import Main from "../views/Main.vue";
import SUPAOrganisms from "../views/SUPAOrganisms.vue";
import SUPAOrganism from "../views/SUPAOrganism.vue";
import SUPACell from "../views/SUPACell.vue";
import SUPACells from "../views/SUPACells.vue";
import Viruses from "../views/Viruses.vue";
import Virus from "../views/Virus.vue";
import MutationPoints from "../views/MutationPoints.vue";
import SFuel from "../views/SFuel.vue";
import Refer from "../views/Refer.vue";
import Pods from "../views/Pods.vue";
import SUPACellPods from "../views/SUPACellPods.vue";
import VirusPods from "../views/VirusPods.vue";
import VirusPodEpic from "../views/VirusPodEpic.vue";
import SUPACellPodEpic from "../views/SUPACellPodEpic.vue";

Vue.use(VueRouter);

const routes  = [
  {
    path: "/supaorganism/:id",
    name: "SUPAOrganism",
    component: SUPAOrganism,
},
{
  path: "/virus/:id",
  name: "Virus",
  component: Virus,
},
{
  path: "/viruspodepic/:id",
  name: "VirusPodEpic",
  component: VirusPodEpic,
},
{
  path: "/supacellpodepic/:id",
  name: "SUPACellPodEpic",
  component: SUPACellPodEpic,
},
{
  path: "/supacell/:id",
  name: "SUPACell",
  component: SUPACell,
},
{
  path: "/sfuel",
  name: "SFuel",
  component: SFuel,
},
{
  path: "/mutationpoints",
  name: "MutationPoints",
  component: MutationPoints,
},
{
  path: "/r/:ref",
  name: "refer",
  component: Refer
},
{
  path: "/pods",
  name: "Pods",
  component: Pods,
},
{
  path: "/supacellpods",
  name: "SUPACellPods",
  component: SUPACellPods,
},
{
  path: "/viruspods",
  name: "VirusPods",
  component: VirusPods,
},
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/main",
    name: "Main",
    component: Main,
  },
  {
    path: "/supaorganisms",
    name: "SUPAOrganisms",
    component: SUPAOrganisms,
  },
  {
    path: "/supacells",
    name: "SUPACells",
    component: SUPACells,
  },
  {
    path: "/viruses",
    name: "Viruses",
    component: Viruses,
  },
  {
    path: "/mint",
    name: "Mint",
    component: Mint,
  },
  {
    path: "/sign-in/:id/:ref?",
    name: "signIn",
    component: SignIn
},
  {
    path: "/profile",
    name: "Profile",

    // route level code-splitting
    // https://router.vuejs.org/guide/advanced/lazy-loading.html
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
  },
  {
    path: "/set-value",
    name: "SetValue",

    component: () =>
      import(/* webpackChunkName: "set-value" */ "../views/SetValue.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
});

export default router;
