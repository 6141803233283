























































import { mapGetters } from "vuex";
const click = require("@/audio/click.mp3");
import { ethers } from "ethers";
import SUPAFoundation from "@/contracts/SUPAFoundation.json";
import addresses from "@/contracts/addresses.json";
export default {
  name: "Home",
  computed: {
    ...mapGetters("accounts", ["getChainData", "isUserConnected"]),
        ...mapGetters("controls", ["isPlaying"]),

  },
   async created() {
     this.$store.dispatch("accounts/getIsLoggedIn");
       await this.connect();

    if (!this.getWeb3Provider) {
      document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
      const contractAddress = addresses.Greeter[parseInt(this.getChainId)];
      this.contract = new ethers.Contract(contractAddress, SUPAFoundation.abi, signer);

    
    }
  },
  methods:{
playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
   data() {
    return {
        audioclick:new Audio(click),
     
         

    };
  },
};
