const sound = require("../../audio/audio.mp3");

// import WalletConnectProvider from '@walletconnect/web3-provider'
// export const defaultProvider = new ethers.providers.InfuraProvider(null, process.env.VUE_APP_INFURA_ID);



// initial state
const state = () => ({
  audio:new Audio(sound),
  playMusic:false,

});
//"isUserLoggedIn","getUserToken"
// getters
const getters = {
  isPlaying(state) {
    return state.playMusic;
  },

 
};

// actions
const actions = {
  playMusic({commit},params){
      if(params.toPlay==true){
        commit('setPlayMusic',true)
      }else{
        commit('setPlayMusic',false)
      }
  }


};

// mutations
const mutations = {
    setPlayMusic(state, toPlay) {
      if(toPlay==true){

        state.playMusic=true;
        state.audio.loop=true;
        state.audio.play();
      }else{
        state.playMusic=false;
        state.audio.pause();
      }
        
   
    
      },
   
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
