



































































import { mapGetters, mapActions } from "vuex";
const click = require("@/audio/click.mp3");
import { ethers } from "ethers";
import VIRUSPODEPIC from "@/contracts/VIRUSPODEPIC.json";


const success = require("@/audio/success.mp3");

import addresses from "@/contracts/addresses.json";
export default {
  name: "SUPACellPods",
  computed: {
            ...mapGetters("controls", ["isPlaying"]),

  ...mapGetters("accounts", ["getChainData", "isUserConnected",  "getWeb3Provider","isInitialized",
      "isUserConnected",
      "getChainId","getAccountDetails","getActiveAccount"
])  },
   async created() {
     this.$store.dispatch("accounts/getIsLoggedIn");
       await this.connect();

    if (!this.getWeb3Provider) {
      //document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();

  const VIRUSPODEPICAddress = addresses.VIRUSPODEPIC[parseInt(this.getChainId)];

       this.VIRUSPODEPICContract=new ethers.Contract(VIRUSPODEPICAddress, VIRUSPODEPIC.abi, signer);

 
    try {
         
         this.VIRUSPODEPICCount= await this.VIRUSPODEPICContract.balanceOf(address);
         for(let i=0;i<this.VIRUSPODEPICCount;i++){
            let tokenId=await  this.VIRUSPODEPICContract.tokenOfOwnerByIndex(address,i)
                                
            let color=await  this.VIRUSPODEPICContract.podType(tokenId);
            

      this.SUPAPodsList.push({id:tokenId, img:"https://supafoundation.b-cdn.net/Pod/Virus/Epic/"+color+".png",counter:i+1})



           

          }
        if(this.VIRUSPODEPICCount<4){
          for(let i=0;i<4-this.VIRUSPODEPICCount;i++){
          this.SUPAPodsList.push({id:null,img:"https://supafoundation.b-cdn.net/assets/images/placeholder.png",counter:this.VIRUSPODEPICCount+i+1})

          }
        }
        if(this.VIRUSPODEPICCount==0){
          console.log("here")
            for(let i=0;i<4;i++){
          this.placeHolder.push({img:"https://supafoundation.b-cdn.net/assets/images/placeholder.png",counter:i+1})

          }
        }

      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }

    
    }
  },
  methods:{
  
      goBack() {
     this.$router.push('/pods')
    },
            ...mapActions("accounts", ["connect"]),
viewOrganism(id){
  this.$router.push('/viruspodepic/'+id)
},
playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
   data() {
    return {
        audioclick:new Audio(click),
        audiosuccess:new Audio(success),
      playMusic:false,
      VIRUSPODEPICCount:0,
    
      SUPAPodsList:[],
      placeHolder:[],   
      waiting:false,

    };
  },
};
