

















































import { mapGetters, mapActions } from "vuex";
import { Collapse } from "bootstrap";
import MobileAction from '@/components/MobileAction.vue'
const click = require("@/audio/click.mp3");

export default {
  name: "Navbar",
  data() {
    return {
      toggledNav: false,
         audioclick:new Audio(click),
    };
  },
    components: {
    MobileAction,
    
  },
  computed: {
    ...mapGetters("accounts", [
      "getActiveAccount",
      "isUserConnected",
      "getWeb3Modal",
      "getChainData",
      "isUserLoggedIn",
      "getChainId",
      "getSUPABalanceEth"
    ]),
    nickname: function () {
      return (
        this.getActiveAccount.slice(0, 9) +
        "..." +
        this.getActiveAccount.slice(-4)
      );
    },
  },
  created() {
    this.$store.dispatch("accounts/init");
  },
  methods: {
    ...mapActions("accounts", ["connect", "disconnect"]),
     playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
      addNetwork(){
window.ethereum.request({
method: 'wallet_addEthereumChain',
params: [{
chainId: '0xfa',
chainName: 'Fantom Opera Network',
nativeCurrency: {
    name: 'Fantom',
    symbol: 'FTM',
    decimals: 18
},
rpcUrls: ['https://rpc.ftm.tools/'],
blockExplorerUrls: ['https://ftmscan.com']
}]
})
.catch((error) => {
console.log(error)
}) 
    },
      logout(){
this.$store.dispatch("accounts/disconnectDiscord");

    },
    openDiscordLogin: function(){
   
                        window.location.href = 'https://api2.supa.foundation/auth/discord';

    },
  },
  watch: {
    $route() {
      this.toggledNav = !this.toggledNav;
      const collapseElementList = [].slice.call(
        document.querySelectorAll(".navbar-collapse.show")
      );
      collapseElementList.map(function (collapseEl) {
        return new Collapse(collapseEl);
      });
    },
  },
};
