import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vSelect from 'vue-select'
import Vuesax from 'vuesax';
import SimpleVueValidation from 'simple-vue-validator';
Vue.use(SimpleVueValidation);
import Toasted from "vue-toasted";
import VueCountdown from '@chenfengyuan/vue-countdown';
Vue.config.ignoredElements = [/^ion-/]
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)
Vue.component(VueCountdown.name, VueCountdown);
// import "bootstrap";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.css";
import './assets/scss/bundles.scss';
import './assets/scss/style.scss';
import "@/assets/css/cursor.css";

import "vue-select/src/scss/vue-select.scss";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueClipboard from 'vue-clipboard2'


Vue.use(VueClipboard);

library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(Toasted);
Vue.component('v-select', vSelect)
import '@/assets/css/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
})

Vue.toasted.register("app_error", "Oops.. Something Went Wrong.", {
  type: "error",
  position: "top-center",
  theme: "bubble",
  duration: 5000,
});
Vue.toasted.register(
  "app_success",
  (payload) => {
    if (!payload.message) {
      return "Success!";
    } else {
      return payload.message;
    }
  },
  {
    type: "success",
    position: "top-center",
    theme: "bubble",
    duration: 5000,
  }
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
