































































import { mapGetters, mapActions } from "vuex";
import { ethers } from "ethers";
import SUPAFoundation from "@/contracts/SUPAFoundation.json";
import SUPACell from "@/contracts/SUPACell.json";
import SUPAVirus from "@/contracts/SUPAVirus.json";
import SUPAOrganism from "@/contracts/SUPAOrganism.json";
import SUPACELLPODEPIC from "@/contracts/SUPACELLPODEPIC.json";
import VIRUSPODEPIC from "@/contracts/VIRUSPODEPIC.json";
const click = require("@/audio/click.mp3");

import addresses from "@/contracts/addresses.json";
export default {
  name: "Pods",
  computed: {
            ...mapGetters("controls", ["isPlaying"]),

  ...mapGetters("accounts", ["getChainData", "isUserConnected",  "getWeb3Provider","isInitialized",
      "isUserConnected",
      "getChainId","getAccountDetails","getActiveAccount"
])  },
   async created() {
     this.$store.dispatch("accounts/getIsLoggedIn");
       await this.connect();

    if (!this.getWeb3Provider) {
      //document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();

      const VIRUSPODEPICAddress = addresses.VIRUSPODEPIC[parseInt(this.getChainId)];
       this.VIRUSPODEPICContract=new ethers.Contract(VIRUSPODEPICAddress, VIRUSPODEPIC.abi, signer);

  
      const SUPACELLPODEPICAddress = addresses.SUPACELLPODEPIC[parseInt(this.getChainId)];

       this.SUPACELLPODEPICContract=new ethers.Contract(SUPACELLPODEPICAddress, SUPACELLPODEPIC.abi, signer);


    try {
         
         this.VIRUSPODEPICCount= await this.VIRUSPODEPICContract.balanceOf(address);
         this.SUPACELLPODEPICCount= await  this.SUPACELLPODEPICContract.balanceOf(address);



      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }

    
    }
  },
  methods:{
            ...mapActions("accounts", ["connect"]),
viewPodVirus(){
  this.$router.push('/viruspods')
},
viewPodCell(){
  this.$router.push('/supacellpods')
},

playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
  goBack() {
     this.$router.push('/main') 
    },

 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
   data() {
    return {
      playMusic:false,
 
      SUPAOrganismCount:0,
      VIRUSPODEPICCount:0,
     SUPACELLPODEPICCount:0,
               audioclick:new Audio(click),
  

    };
  },
};
