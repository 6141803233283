











































































import { mapGetters, mapActions } from "vuex";
import { ethers } from "ethers";
import SUPAFoundation from "@/contracts/SUPAFoundation.json";
import SUPACell from "@/contracts/SUPACell.json";
import SUPAVirus from "@/contracts/SUPAVirus.json";
import SUPAOrganism from "@/contracts/SUPAOrganism.json";
const click = require("@/audio/click.mp3");

import addresses from "@/contracts/addresses.json";
export default {
  name: "Main",
  computed: {
            ...mapGetters("controls", ["isPlaying"]),

  ...mapGetters("accounts", ["getChainData", "isUserConnected",  "getWeb3Provider","isInitialized",
      "isUserConnected",
      "getChainId","getAccountDetails","getActiveAccount"
])  },
   async created() {
     this.$store.dispatch("accounts/getIsLoggedIn");
       await this.connect();

    if (!this.getWeb3Provider) {
      //document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();

  const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];

       this.supaOrganismContract=new ethers.Contract(supaOrganismContractAddress, SUPAOrganism.abi, signer);

  const supaVirusContractAddress = addresses.SUPAVirus[parseInt(this.getChainId)];

       this.supaVirusContract=new ethers.Contract(supaVirusContractAddress, SUPAVirus.abi, signer);

  const supaCellContractAddress = addresses.SUPACell[parseInt(this.getChainId)];

       this.supaCellContract=new ethers.Contract(supaCellContractAddress, SUPACell.abi, signer);
    try {
         
         this.SUPAOrganismCount= await this.supaOrganismContract.balanceOf(address);
         this.SUPACellCount= await this.supaCellContract.balanceOf(address);

         this.SUPAVirusCount= await this.supaVirusContract.balanceOf(address);


      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }

    
    }
  },
  methods:{
            ...mapActions("accounts", ["connect"]),
viewOrganisms(){
  this.$router.push('/supaorganisms')
},
viewSUPACells(){
  this.$router.push('/supacells')
},
viewViruses(){
  this.$router.push('/viruses')
},
viewMutationPoints(){
  this.$router.push('/mutationpoints')
},
viewsFuel(){
  this.$router.push('/sfuel')
},
viewsPods(){
  this.$router.push('/pods')
},
playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
  goBack() {
     this.$router.push('/mint') 
    },

 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
   data() {
    return {
      playMusic:false,
 
      SUPAOrganismCount:0,
      SUPACellCount:0,
      SUPAVirusCount:0,
               audioclick:new Audio(click),
  

    };
  },
};
