



































































































import { mapGetters, mapActions } from "vuex";
import { ethers } from "ethers";
import SUPAFoundation from "@/contracts/SUPAFoundation.json";
import SUPACell from "@/contracts/SUPACell.json";
import SUPAVirus from "@/contracts/SUPAVirus.json";
import SUPAOrganism from "@/contracts/SUPAOrganism.json";
const click = require("@/audio/click.mp3");
import { ref, onUpdated } from '@vue/composition-api'
import {useStore} from '@vueblocks/vue-use-vuex'
import * as dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
import addresses from "@/contracts/addresses.json";
export default {
  name: "SFuel",
  computed: {
            ...mapGetters("controls", ["isPlaying"]),

  ...mapGetters("accounts", ["getChainData", "isUserConnected",  "getWeb3Provider","isInitialized",
      "isUserConnected",
      "getChainId","getAccountDetails","getActiveAccount","isUserLoggedIn","getGroupDetails","getSUPAUsername"
])  },
   async created() {
     this.$store.dispatch("accounts/getIsLoggedIn");
       await this.connect();

    if (!this.getWeb3Provider) {
      //document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();

  const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];

       this.supaOrganismContract=new ethers.Contract(supaOrganismContractAddress, SUPAOrganism.abi, signer);

  const supaVirusContractAddress = addresses.SUPAVirus[parseInt(this.getChainId)];

       this.supaVirusContract=new ethers.Contract(supaVirusContractAddress, SUPAVirus.abi, signer);

  const supaCellContractAddress = addresses.SUPACell[parseInt(this.getChainId)];

       this.supaCellContract=new ethers.Contract(supaCellContractAddress, SUPACell.abi, signer);
    try {
         
         this.SUPAOrganismCount= await this.supaOrganismContract.balanceOf(address);
         this.SUPACellCount= await this.supaCellContract.balanceOf(address);

         this.SUPAVirusCount= await this.supaVirusContract.balanceOf(address);


      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }

    
    }
  },
  setup(){
    const store = useStore()
    let balEnd = ref(0);
  let balStart = ref(0);
  const progress=ref(0)
    const currentBal=ref(0);
    const counterString=ref("")
      const secondsRemaining=ref(0)
    const showCounter=ref(false)
    const currentRate=ref(0);
    let timerz=ref();
    let mined=ref(false);
   // let counterss=ref(0)
    let countz=ref(0)
     const updateDisplaySetup=(balStart,balEnd,endTime)=>{
  // console.log(counterss.value++)
   const timestamp = Math.round(Date.now() / 1000);
   const diff=parseInt(endTime, 10)-timestamp
  

      if(diff>0){
        
        if(balEnd.value==undefined){
                     


      currentBal.value=parseFloat(((((balEnd-balStart)*( (86400-diff) / 86400))+balStart)/1000).toFixed(3));
        counterString.value=dayjs.duration(diff*1000).format('HH:mm:ss')
        secondsRemaining.value=diff;
        showCounter.value=true
        currentRate.value=parseFloat(((balEnd-(currentBal.value*1000))/(secondsRemaining.value/3600)/1000).toFixed(1))
        }
  
       

      else{
                

       showCounter.value=true
        currentBal.value=parseFloat(((((balEnd.value-balStart.value)*( (86400-diff) / 86400))+balStart.value)/1000).toFixed(3));
        counterString.value=dayjs.duration(diff*1000).format('HH:mm:ss')
        secondsRemaining.value=diff;
        currentRate.value=parseFloat(((balEnd.value-(currentBal.value*1000))/(secondsRemaining.value/3600)/1000).toFixed(1))
       }
       
       let counter=ref(0)
         if(counter.value==0){
           counter.value++
       
         }
if(secondsRemaining.value<=0){
  
          clearInterval(timerz.value)
         
         secondsRemaining.value=0
       
          return
        }
     

      //  Interval.addTask(runTimerSetup);
       

      } else{

          clearInterval(timerz.value)
      counterString.value=dayjs.duration(0*1000).format('HH:mm:ss')
         secondsRemaining.value=0
        currentBal.value=parseFloat((balEnd.value/1000).toFixed(3));
       showCounter.value=false
    
      }
   } 


  onUpdated(() => {
     

    if(countz.value==0 || mined.value==true){
                
      countz.value++
      mined.value=false
     
 balEnd.value = (store.state.accounts.accountDetails.balEnd === undefined) ? 0 : store.state.accounts.accountDetails.balEnd;
   balStart.value = (store.state.accounts.accountDetails.balStart === undefined) ? 0 : store.state.accounts.accountDetails.balStart;
//console.log(store.state.accounts.accountDetails)
  timerz.value=setInterval(() => {
                updateDisplaySetup(balStart,balEnd,store.state.accounts.accountDetails.endTime);
            },1000);
  }
    })

     const goMine=()=>{
     
          let component = this;

let idToken=localStorage.getItem('jwtToken')
if(idToken!=null && idToken!=''){
  idToken=idToken.toString();
  // Send token to your backend via HTTPS
  // ...
     let formData = new FormData();
formData.append('token', idToken);
fetch('https://api.supa.foundation/dmine', {
  method: "POST",
  body: formData,
 
})
.then(response => response.json()) 
.then(json => {
  if (json.error==true){
     component.$vs.notification({
           color: 'danger',
            position:'top-center',
            title: 'Error!',
            text: 'Unable to start sFuel farming process'
          })
     

  }else{
    if(idToken!=null && idToken!=''){
  idToken=idToken.toString();

       let formData = new FormData();
formData.append('token', idToken);
fetch('https://api.supa.foundation/refreshDiscordUser', {
  method: "POST",
  body: formData,
 
})
.then(response => response.json()) 
.then(json => {
  if (json.userinfo.length==0){
                  this.$toasted.global.app_error();

       

  }else{
   // showCounter.value=true
  const balEnd = (json.userinfo[0].balEnd === undefined) ? 0 : json.userinfo[0].balEnd;
  const balStart = (json.userinfo[0].balStart === undefined) ? 0 : json.userinfo[0].balStart;
    updateDisplaySetup(balStart,balEnd,json.userinfo[0].endTime)
    mined.value=true;

    store.commit("accounts/saveAccountDetails",json.userinfo[0])
    store.commit("accounts/saveGroupInfo",json.group)
  
      
  }
  
 
})
.catch(err => console.log(err));
   //  showCounter.value=true

      
  }
  
  }
})
.catch(err => console.log(err));

    }
  
 }
  
    const answer=ref(0)
    const step=ref(0)
    const setAnswer=(val)=>{
      if(val==1 || val==2){
      answer.value=val
      }

    }
      const skipToMint=()=>{
      
      answer.value=1
      step.value=2

    }
     const reset=()=>{
      
      answer.value=0
      step.value=0

    }
    return{
      step,
      setAnswer,
      answer,
      skipToMint,
      reset,
      goMine,
    currentRate,
        currentBal,
        counterString,
        secondsRemaining,
     progress,
      balEnd,
      showCounter
    }
  },
  methods:{
    notifyCopied(){
  this.$toasted.global.app_success({
               message: "Referral link copied",
               });
    },
            ...mapActions("accounts", ["connect"]),
viewOrganisms(){
  this.$router.push('/supaorganisms')
},

viewSUPACells(){
  this.$router.push('/supacells')
},
viewViruses(){
  this.$router.push('/viruses')
},
viewMutationPoints(){
  this.$router.push('/mutationpoints')
},
viewsFuel(){
  this.$router.push('/sfuel')
},
playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
  goBack() {
     this.$router.push('/main') 
    },
   openDiscordLogin: function(){
   
                        window.location.href = 'https://api2.supa.foundation/auth/discord';

    },
 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
   data() {
    return {
      playMusic:false,
 
      SUPAOrganismCount:0,
      SUPACellCount:0,
      SUPAVirusCount:0,
               audioclick:new Audio(click),
      timeNow:Math.round(Date.now() / 1000),

  

    };
  },
};
