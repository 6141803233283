














































































































































































import { mapGetters, mapActions } from "vuex";
const click = require("@/audio/click.mp3");

import { ethers } from "ethers";
import SUPAFoundation from "@/contracts/SUPAFoundation.json";
import SUPACell from "@/contracts/SUPACell.json";
import SUPAVirus from "@/contracts/SUPAVirus.json";
import SUPAOrganism from "@/contracts/SUPAOrganism.json";
import MutationPointsFarming from "@/contracts/MutationPointsFarming.json";
const success = require("@/audio/success.mp3");

import addresses from "@/contracts/addresses.json";
export default {
  name: "SUPACell",
  computed: {
            ...mapGetters("controls", ["isPlaying"]),

  ...mapGetters("accounts", ["getChainData", "isUserConnected",  "getWeb3Provider","isInitialized",
      "isUserConnected",
      "getChainId","getAccountDetails","getActiveAccount"
])  },
   async created() {
            let component=this

        this.organismId= this.$route.params.id
    fetch('https://api3.supa.foundation/supacell/'+this.organismId+'.json', { 
            method: 'GET'
          })
          .then(function(response) { return response.json(); })
          .then(function(json) {
          
          component.NFTdata=json
          if(json.heropower.length==2){
            if(json.heropower[0]==0){
              component.NFTdata.herotext="RD"
            }else if(json.heropower[0]==1){
              component.NFTdata.herotext="GR"
            }else if(json.heropower[0]==2){
              component.NFTdata.herotext="BK"
            }else if(json.heropower[0]==3){
              component.NFTdata.herotext="BU"
            }else if(json.heropower[0]==4){
              component.NFTdata.herotext="WH"
            }else if(json.heropower[0]==5){
              component.NFTdata.herotext="OR"
            }else {
              component.NFTdata.herotext="PR"
            }
               if(json.heropower[1]==0){
              component.NFTdata.herotext+=",RD"
            }else if(json.heropower[1]==1){
              component.NFTdata.herotext+=",GR"
            }else if(json.heropower[1]==2){
              component.NFTdata.herotext+=",BK"
            }else if(json.heropower[1]==3){
              component.NFTdata.herotext+=",BU"
            }else if(json.heropower[1]==4){
              component.NFTdata.herotext+=",WH"
            }else if(json.heropower[1]==5){
              component.NFTdata.herotext+=",OR"
            }else {
              component.NFTdata.herotext+=",PR"
            }
          }else{
          if(json.heropower[0]==0){
              component.NFTdata.herotext="RD"
            }else if(json.heropower[0]==1){
              component.NFTdata.herotext="GR"
            }else if(json.heropower[0]==2){
              component.NFTdata.herotext="BK"
            }else if(json.heropower[0]==3){
              component.NFTdata.herotext="BU"
            }else if(json.heropower[0]==4){
              component.NFTdata.herotext="WH"
            }else if(json.heropower[0]==5){
              component.NFTdata.herotext="OR"
            }else {
              component.NFTdata.herotext="PR"
            }
          }
           component.SUPAsound=json.animation_url
          });
     this.$store.dispatch("accounts/getIsLoggedIn");
       await this.connect();

    if (!this.getWeb3Provider) {
      //document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();

  const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];

       this.supaOrganismContract=new ethers.Contract(supaOrganismContractAddress, SUPAOrganism.abi, signer);

  const supaVirusContractAddress = addresses.SUPAVirus[parseInt(this.getChainId)];

       this.supaVirusContract=new ethers.Contract(supaVirusContractAddress, SUPAVirus.abi, signer);

  const supaCellContractAddress = addresses.SUPACell[parseInt(this.getChainId)];

       this.supaCellContract=new ethers.Contract(supaCellContractAddress, SUPACell.abi, signer);
          const mutationPointsFarmingContractAddress = addresses.MutationPointsFarming[parseInt(this.getChainId)];

       this.mutationPointsFarmingContract=new ethers.Contract(mutationPointsFarmingContractAddress , MutationPointsFarming.abi, signer);
      
let component=this
  this.supaCellContract.on("mutationSetupSuccessful", async (tokenId) => {
    if(await this.supaCellContract.ownerOf(tokenId)==address){
    this.organismIdentified=true;
   this.$toasted.global.app_success({
               message: "Mutation identification Successful",
               });
    this.mutationsIdentified=true;
    }
    
    
      });
  this.supaVirusContract.on("mutationSetupSuccessful", async (tokenId) => {
    if(await this.supaCellContract.ownerOf(tokenId)==address){

    this.organismIdentified=true;
    this.$toasted.global.app_success({
               message: "Mutation identification Successful",
               });
    this.mutationsIdentified=true;

    }
    
    
      });



    try {     this.lastFarmed= await this.mutationPointsFarmingContract.lastFarmedCell(this.organismId)
              this.isOwner= await this.supaCellContract.ownerOf(this.organismId)==address

         if(this.isOwner){
         this.organismIdentified= await this.supaCellContract.setupMutation(this.organismId);
             this.mutationsIdentified=this.organismIdentified;

         }


      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }

    
    }
  },
  methods:{
   async feed(){
  this.waiting=true;
 let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();
   const mutationPointsFarmingContractAddress = addresses.MutationPointsFarming[parseInt(this.getChainId)];

       this.mutationPointsFarmingContract=new ethers.Contract(mutationPointsFarmingContractAddress , MutationPointsFarming.abi, signer);
          try {
       let transaction=  await this.mutationPointsFarmingContract.farm(this.organismId,1);
                await transaction.wait().then(async (receipt) => {
               this.$toasted.global.app_success({
               message: "Congrats! You fed the SUPACell",
               });
               this.waiting = false;
                this.audiosuccess.play();
              this.lastFarmed= await this.mutationPointsFarmingContract.lastFarmedCell(this.organismId)

               })
           }
           catch(err){
             this.$toasted.global.app_error();
            this.waiting = false;
            console.log(err);
           }
    },
      goBack() {
     this.$router.push('/supacells') 
    },
            ...mapActions("accounts", ["connect"]),
viewOrganisms(id){
  this.$router.push('/supaorganism/'+id)
},
async identifyMutation(){
               this.waiting = true;

         let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
  const supaCellContractAddress = addresses.SUPACell[parseInt(this.getChainId)];
       this.supaCellContract=new ethers.Contract(supaCellContractAddress, SUPACell.abi, signer);
       try {
               let component=this
  fetch("https://api2.supa.foundation/identifysupacell/"+this.organismId)
    .then(response => response.json())
    .then(async data => {
      if(data.error===true){
        component.$toasted.show('An error occured', {
          type: 'error',
          duration: 5000,
          theme: "bubble",
          position: "top-center"
        });
      } else {
        
        await this.supaCellContract.setupMutationPoints(this.organismId,
        data.response.RD,
        data.response.GR,
        data.response.BK,
        data.response.BU,
        data.response.WH,
        data.response.OR,
        data.response.PR,
        data.response.signature);
      }
    })

           }
           catch(err){
             this.$toasted.global.app_error();
            this.waiting = false;
            console.log(err);
           }


    

},
playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
 playSUPASound(){
        let audio = this.$refs.audio;
          audio.play();
        
    },
 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
   data() {
    return {
              audiosuccess:new Audio(success),

        audioclick:new Audio(click),
      playMusic:false,
      SUPAOrganismCount:0,
      SUPACellCount:0,
      SUPAVirusCount:0,
      SUPAOrganismList:[],
      isActive:false,
      NFTdata:null,
      organismIdentified:true,
      waiting:false,
      isOwner:false,
      mutationsIdentified:false,
      SUPASound:null,
      lastFarmed:1,
   
    };
  },
};
