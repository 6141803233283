































































































































import { mapGetters, mapActions } from "vuex";
const click = require("@/audio/click.mp3");
import { ethers } from "ethers";
import SUPAFoundation from "@/contracts/SUPAFoundation.json";
import SUPAOrganism from "@/contracts/SUPAOrganism.json";
import SUPAVerseComicI1C1P1 from "@/contracts/SUPAVerseComicI1C1P1.json"
import addresses from "@/contracts/addresses.json";
import jwt_decode from "jwt-decode";

export default {
  name: "Mint",
  computed: {
            ...mapGetters("controls", ["isPlaying"]),

    ...mapGetters("accounts", ["getChainData", "isUserConnected",  "getWeb3Provider","isInitialized",
      "isUserConnected",
      "getChainId","getAccountDetails","getActiveAccount"
]),
  },
    data() {
    return {
      supaBalance: 0,
      supaBalanceBN:0,
      allowanceBN:0,
      OGCount:0,
      OGList:[],
      tokenContract: null,
      OGContract:null,
      genesisAllowance:0,
      OGAllowanceCount:0,
      supaOrganismContract:null,
      waiting: false,
      allowance:0,
      hasGenesis:false,
        audioclick:new Audio(click),
      playMusic:false,
      organismCount:0,
      price1:(Date.now()>1642687200000+86400000 && Date.now()<1642687200000+86400000*4)?1000:2000,
      price2:(Date.now()>1642687200000+86400000 && Date.now()<1642687200000+86400000*4)?1750:3500,
      price3:(Date.now()>1642687200000+86400000 && Date.now()<1642687200000+86400000*4)?3000:6000
    };
  },
   async created() {
     this.$store.dispatch("accounts/getIsLoggedIn");
     await this.connect();

    if (!this.getWeb3Provider) {
      document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
       const address = await signer.getAddress();
      const tokenContractAddress = addresses.SUPAFoundation[parseInt(this.getChainId)];
            const OGContractAddress = addresses.SUPAVerseComicI1C1P1[parseInt(this.getChainId)];

      this.tokenContract = new ethers.Contract(tokenContractAddress, SUPAFoundation.abi, signer);
      this.OGContract=new ethers.Contract(OGContractAddress, SUPAVerseComicI1C1P1.abi, signer);
           const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];

       this.supaOrganismContract=new ethers.Contract(supaOrganismContractAddress, SUPAOrganism.abi, signer);

      try {
         this.supaBalanceBN= await this.tokenContract.balanceOf(address);
          this.OGCount= await this.OGContract.balanceOf(address);
         this.organismCount= await this.supaOrganismContract.balanceOf(address);
     if(this.getAccountDetails){
     this.hasGenesis=this.getAccountDetails.hasGenesis

           if(this.hasGenesis==true){
         this.genesisAllowance=2-await this.supaOrganismContract.seenNoncesDiscord(this.getAccountDetails.discordID)

            }
     }

          for(let i=0;i<this.OGCount;i++){
            let tokenId=await this.OGContract.tokenOfOwnerByIndex(address,i)
                     

            let balanceOGallowance=2-await this.supaOrganismContract.seenNoncesOG(tokenId)
           
           if(balanceOGallowance>0){
            this.OGList.push({id:tokenId,allowance:balanceOGallowance})
            this.OGAllowanceCount=this.OGAllowanceCount+balanceOGallowance
           }

          }
            const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];

        let res = ethers.utils.formatEther( this.supaBalanceBN);
        this.supaBalance = Math.round(parseFloat(res) * 1e4) / 1e4;
       
        this.allowanceBN = await this.tokenContract.allowance(address,supaOrganismContractAddress);
         let res1 = ethers.utils.formatEther(this.allowanceBN);
         this.allowance=Math.round(parseFloat(res1) * 1e4) / 1e4;
      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }
  


    }
  },
  methods:{
        ...mapActions("accounts", ["connect"]),
async approve() {
   let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
      const address = await signer.getAddress();
      this.waiting = true;
     const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];
    this.supaOrganismContract=new ethers.Contract(supaOrganismContractAddress, SUPAFoundation.abi, signer);
      try {
        const transaction = await this.tokenContract.approve(supaOrganismContractAddress,this.supaBalanceBN);
        await transaction.wait().then(async (receipt) => {
          this.$toasted.global.app_success({
            message: "Transaction was successful ",
          });
          this.waiting = false;
           
         this.supaBalanceBN= await this.tokenContract.balanceOf(address);
            const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];

        let res = ethers.utils.formatEther( this.supaBalanceBN);
        this.supaBalance = Math.round(parseFloat(res) * 1e4) / 1e4;
       
        this.allowanceBN = await this.tokenContract.allowance(address,supaOrganismContractAddress);
         let res1 = ethers.utils.formatEther(this.allowanceBN);
         this.allowance=Math.round(parseFloat(res1) * 1e4) / 1e4;
      
          console.log(receipt);
        });
      } catch (err) {
        this.$toasted.global.app_error();
        this.waiting = false;
        console.log(err);
      }
    },
    async purchase(qty){
      let click=1
         let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
  const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];
       this.supaOrganismContract=new ethers.Contract(supaOrganismContractAddress, SUPAOrganism.abi, signer);
      if(this.genesisAllowance>0){
     
      let token=localStorage.getItem('jwtToken')!.toString()
      
           let component=this
fetch("https://api2.supa.foundation/mintOrganismDiscord/"+token)
    .then(response => response.json())
    .then(async data => {
      if(data.error===true){
        component.$toasted.show('Error: Please check login details & that you have the SUPA Recruit Role', {
          type: 'error',
          duration: 5000,
          theme: "bubble",
          position: "top-center"
        });
      } else {
           try {
       let transaction= await this.supaOrganismContract.mintWithGenesis(this.getAccountDetails.discordID,qty,data.response.signature);
                await transaction.wait().then(async (receipt) => {
               this.$toasted.global.app_success({
               message: "Congrats! Mint successful ",
               });
               this.waiting = false;
               if(click>0){
                this.genesisAllowance--;
                  this.mintSuccessful()
               }
               })
           }
           catch(err){
             this.$toasted.global.app_error();
            this.waiting = false;
            console.log(err);
           }
      }
      
      
    })
      }else if(this.OGAllowanceCount>0){
             try {
       let transaction= await this.supaOrganismContract.mintWithOGNFT(this.OGList[0].id,qty);

                await transaction.wait().then(async (receipt) => {
               this.$toasted.global.app_success({
               message: "Congrats! Mint successful ",
               });
               this.waiting = false;
                if(click>0){
              this.OGAllowanceCount--;
               this.mintSuccessful()

               }

               })
           }
           catch(err){
             this.$toasted.global.app_error();
            this.waiting = false;
            console.log(err);
           }

      }else{
             try {
       let transaction=  await this.supaOrganismContract.mint(qty);
                await transaction.wait().then(async (receipt) => {
               this.$toasted.global.app_success({
               message: "Congrats! Mint successful ",
               });
               this.waiting = false;
                this.mintSuccessful()
               })
           }
           catch(err){
             this.$toasted.global.app_error();
            this.waiting = false;
            console.log(err);
           }

      }


    },
async mintSuccessful(){
       this.$store.dispatch("accounts/fetchActiveBalance");

  let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
       const address = await signer.getAddress();
  this.supaBalanceBN= await this.tokenContract.balanceOf(address);
          this.OGCount= await this.OGContract.balanceOf(address);
     this.organismCount= await this.supaOrganismContract.balanceOf(address);
          this.OGList=[]
         
          for(let i=0;i<this.OGCount;i++){
            let tokenId=await this.OGContract.tokenOfOwnerByIndex(address,i)
                     

            let balanceOGallowance=2-await this.supaOrganismContract.seenNoncesOG(tokenId)
           
           if(balanceOGallowance>0){
         
            
          this.OGList.push({id:tokenId,allowance:balanceOGallowance})
              
            
           }

          }
            const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];

        let res = ethers.utils.formatEther( this.supaBalanceBN);
        this.supaBalance = Math.round(parseFloat(res) * 1e4) / 1e4;
       
        this.allowanceBN = await this.tokenContract.allowance(address,supaOrganismContractAddress);
         let res1 = ethers.utils.formatEther(this.allowanceBN);
         this.allowance=Math.round(parseFloat(res1) * 1e4) / 1e4;
} ,
playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
    goBack() {
     this.$router.push('/')
    },

 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
  
};
