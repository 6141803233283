






































































import { mapGetters, mapActions } from "vuex";
const click = require("@/audio/click.mp3");
const success = require("@/audio/success.mp3");

import { ethers } from "ethers";
import SUPAFoundation from "@/contracts/SUPAFoundation.json";
import SUPACell from "@/contracts/SUPACell.json";
import SUPAVirus from "@/contracts/SUPAVirus.json";
import SUPAOrganism from "@/contracts/SUPAOrganism.json";
import MutationPointsFarming from "@/contracts/MutationPointsFarming.json";
import addresses from "@/contracts/addresses.json";
export default {
  name: "SUPAOrganisms",
  computed: {
            ...mapGetters("controls", ["isPlaying"]),

  ...mapGetters("accounts", ["getChainData", "isUserConnected",  "getWeb3Provider","isInitialized",
      "isUserConnected",
      "getChainId","getAccountDetails","getActiveAccount"
])  },
   async created() {
     this.$store.dispatch("accounts/getIsLoggedIn");
       await this.connect();

    if (!this.getWeb3Provider) {
      //document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();

  const supaOrganismContractAddress = addresses.SUPAOrganism[parseInt(this.getChainId)];

       this.supaOrganismContract=new ethers.Contract(supaOrganismContractAddress, SUPAOrganism.abi, signer);

  const supaVirusContractAddress = addresses.SUPAVirus[parseInt(this.getChainId)];

       this.supaVirusContract=new ethers.Contract(supaVirusContractAddress, SUPAVirus.abi, signer);

  const supaCellContractAddress = addresses.SUPACell[parseInt(this.getChainId)];

       this.supaCellContract=new ethers.Contract(supaCellContractAddress, SUPACell.abi, signer);
     
    try {
         
         this.SUPAOrganismCount= await this.supaCellContract.balanceOf(address);
         for(let i=0;i<this.SUPAOrganismCount;i++){
            let tokenId=await  this.supaCellContract.tokenOfOwnerByIndex(address,i)
                                
          
            this.SUPAOrganismList.push({id:tokenId, img:"https://supafoundation.b-cdn.net/assets/images/SUPA_Organism.png",counter:i+1})
           

          }
        if(this.SUPAOrganismCount<4){
          for(let i=0;i<4-this.SUPAOrganismCount;i++){
          this.SUPAOrganismList.push({id:null,img:"https://supafoundation.b-cdn.net/assets/images/placeholder.png",counter:this.SUPAOrganismCount+i+1})

          }
        }
        if(this.SUPAOrganismCount==0){
          console.log("here")
            for(let i=0;i<4;i++){
          this.placeHolder.push({img:"https://supafoundation.b-cdn.net/assets/images/placeholder.png",counter:i+1})

          }
        }

      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }

    
    }
  },
  methods:{
    async feedAll(){
      this.waiting=true;
 let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
             const address = await signer.getAddress();
   const mutationPointsFarmingContractAddress = addresses.MutationPointsFarming[parseInt(this.getChainId)];

       this.mutationPointsFarmingContract=new ethers.Contract(mutationPointsFarmingContractAddress , MutationPointsFarming.abi, signer);
          try {
       let transaction=  await this.mutationPointsFarmingContract.farmOwner(1,0,this.SUPAOrganismCount);
                await transaction.wait().then(async (receipt) => {
               this.$toasted.global.app_success({
               message: "Congrats! You fed all SUPACells",
               });
               this.waiting = false;
                this.audiosuccess.play();
               })
           }
           catch(err){
             this.$toasted.global.app_error();
            this.waiting = false;
            console.log(err);
           }


    },
      goBack() {
     this.$router.push('/main')
    },
            ...mapActions("accounts", ["connect"]),
viewOrganism(id){
  this.$router.push('/supacell/'+id)
},
playmusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:true});


},
pausemusic(){
   this.$store.dispatch("controls/playMusic",{toPlay:false});


},
 playclick(){
      this.audioclick.volume=0.3;
      this.audioclick.play();
    },
  },
   data() {
    return {
        audioclick:new Audio(click),
        audiosuccess:new Audio(success),
      playMusic:false,
      SUPAOrganismCount:0,
      SUPACellCount:0,
      SUPAVirusCount:0,
      SUPAOrganismList:[],
      placeHolder:[],  
      waiting:false, 

    };
  },
};
